import React from "react";
import { Button, Container, Image, Heading, Box } from "theme-ui";
import { Grid, Anchor, scrollToEl, Button as DunkButton, Flex } from "dunkmore";
import "@components/_underline-button.scss";
import logo from "images/signaturewhite.png";
import fb from "images/facebook.png";
import ig from "images/instagram.png";
import { links } from "classes/links";

export default function Header() {
  return (
    <>
      <Grid j={1} a={1} f={1} l={1}>
        <Container px={24}>
          <Grid c={1} a={1} h={["80px", "164px"]} f={1}>
            <Grid
              c={["120px 1fr", "178px 600px 178px"]}
              sx={{ justifyContent: "space-between" }}
              mt={3}
            >
              <Grid a={1}>
                <img
                  src={logo}
                  width={"100%"}
                  alt="jay signature"
                  sx={{
                    maxWidth: "200px",
                    paddingBottom: "8px"
                  }}
                />
              </Grid>
              <Grid v={[0, 0, 0, 1]} c={4} a={1} px={24}>
                {links.slice(0, 4).map(navLeft => (
                  <Button
                    key={navLeft.id}
                    onClick={e => scrollToEl(navLeft.link)}
                    variant="navlink"
                    className="animated-underline"
                  >
                    <span>{navLeft.span}</span>
                  </Button>
                ))}
              </Grid>
              <Box>
                <Flex f={1} a={1} j={2}>
                  {links.slice(4, 7).map(social => (
                    <Anchor to={social.link} t key={social.id} p={16}>
                      <Image src={social.img} />
                    </Anchor>
                  ))}
                </Flex>
              </Box>
            </Grid>
          </Grid>
          {/*
								<DunkButton
									onClick={e => scrollToEl("contact")}
									variant="white"
									color="text"
								>
									Invite Mark
								</DunkButton>
								*/}
        </Container>
      </Grid>
    </>
  );
}
