import podcast1 from "images/podcast/podcast1.jpg"
import podcast2 from "images/podcast/podcast2.jpg"
import podcast3 from "images/podcast/podcast3.jpg"
import podcast4 from "images/podcast/podcast4.jpg"
import podcast5 from "images/podcast/podcast5.jpg"
import podcast6 from "images/podcast/podcast6.jpg"
import podcast7 from "images/podcast/podcast7.jpg"

const Podcast = (img, link) => ({
  img,
  link,
})

export const podcasts = [
  Podcast(podcast1, `https://youtu.be/GlvVklNRgCQ`),
  Podcast(
    podcast2,
    `https://podcasts.apple.com/us/podcast/vanessas-voices/id1472466454#episodeGuid=tag%3Asoundcloud%2C2010%3Atracks%2F719311594`
  ),
  Podcast(
    podcast3,
    `https://podcasts.apple.com/us/podcast/budgetrek/id1476883911#episodeGuid=6905e525-8048-41b5-b25e-a605ee47b5f1`
  ),
  Podcast(
    podcast4,
    `https://podcasts.apple.com/us/podcast/the-bridge/id1492185619#episodeGuid=Buzzsprout-3084973`
  ),
  Podcast(
    podcast5,
    `https://podcasts.apple.com/us/podcast/matt-ode-words-and-a-workout/id1503985367?i=1000469463266`
  ),
  Podcast(
    podcast6,
    `https://podcasts.apple.com/us/podcast/30-change-your-perception-to-save-your-life-matt-ode/id1467523109?i=1000450387807`
  ),
  Podcast(
    podcast7,
    `https://podcasts.apple.com/us/podcast/punching-cancer-in-the-mouth-w-matt-ode-opdich-002/id1470131403?i=1000448464295`
  ),
]
