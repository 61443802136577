import React from "react";
import { Heading, Image, Box } from "theme-ui";
import {
  Grid,
  Container,
  Flex,
  Paragraph,
  Divider,
  Section,
  BorderButton,
  scrollToEl
} from "dunkmore";
import whitecircle from "images/artifacts/whitecircle.svg";
import about from "images/about.jpg";
import about1 from "images/about1.png";
import about2 from "images/about2.png";
import about3 from "images/about3.png";

export default function About() {
  return (
    <>
      <Section l={1} mb={48} sx={{ background: "#fff", zIndex: "3" }}>
        <Container pt={80}>
          <Grid c={[1, "40% 1fr"]} gap={5} p={5}>
            <Flex o={[0, 1]} d={1} j={1} pl={[0, 8]}>
              <Heading
                as="h1"
                color="black"
                variant="heading"
                sx={{ textAlign: ["center", "left"] }}
              >
                <Box sx={{ fontSize: "24px" }}>About</Box>
                <Box>JAY</Box>
              </Heading>
              <Divider
                variant="black"
                sx={{
                  position: "relative",
                  width: "80%",
                  margin: ["18px auto 0", "18px 0 0"]
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "primary",
                    height: "8px",
                    width: "60%",
                    position: "absolute",
                    left: "0",
                    right: ["0", "auto"],
                    bottom: "0",
                    zIndex: "-1",
                    margin: ["0 auto", "0"]
                  }}
                ></Box>
              </Divider>
              <Paragraph mt={"24px"} sx={{ textAlign: ["center", "left"] }}>
                As a successful entrepreneur and loving husband, Jay brings a
                fresh perspective to leadership. His ability to communicate
                authentically compels people to take massive action on improving
                their life.
              </Paragraph>
            </Flex>
            <Flex o={[1, 0]} d={1} l={1} a={1}>
              <Image src={about}></Image>
            </Flex>
          </Grid>
        </Container>
      </Section>
    </>
  );
}
