import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Input, Select, Textarea, Image, Heading } from "theme-ui";
import {
  Grid,
  Flex,
  Button,
  Container,
  Section,
  Divider,
  Paragraph,
  Sheath
} from "dunkmore";
import sendarrow from "images/artifacts/sendarrow.png";
import contact from "images/contact.png";
import firebase from "gatsby-plugin-firebase";
import contactjay from "images/contactjay.jpg";

export default function ContactForm() {
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange"
  });

  const onSubmit = data =>
    true &&
    firebase
      .firestore()
      .collection("/contact-form")
      .add(data);

  const [data, setData] = React.useState(null);

  return (
    <Section
      id="contact"
      variant="black"
      sx={{ position: "relative", zIndex: "2" }}
    >
      <Sheath
        l={1}
        variant="site"
        bgi={`url("${contact}")`}
        bgp={"bottom right"}
        bgr={0}
        bgs={"500px"}
      ></Sheath>
      <Container p={24}>
        <Grid c={["1fr", "1fr", "35% 1fr"]}>
          <Flex o={[0, 0]} pb={48} j={1}>
            <Image
              src={contactjay}
              sx={{ maxHeight: ["500px", "600px"] }}
            ></Image>
          </Flex>
          <Flex d={1} o={[1, 1]}>
            <Flex j={0} a={[1, 0]} d={1} px={[3, 64]}>
              <Heading
                as="h1"
                color="black"
                variant="heading"
                sx={{ textAlign: ["center", "left"] }}
              >
                <Box sx={{ fontSize: "24px" }}>Work with Jay</Box>
                <Box>TODAY</Box>
              </Heading>
              <Divider
                variant="black"
                sx={{
                  position: "relative",
                  width: "80%",
                  margin: ["18px auto 0", "18px 0 0"]
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "primary",
                    height: "8px",
                    width: "60%",
                    position: "absolute",
                    left: "0",
                    right: ["0", "auto"],
                    bottom: "0",
                    zIndex: "-1",
                    margin: ["0 auto", "0"]
                  }}
                ></Box>
              </Divider>
              <Paragraph
                color="black"
                my={6}
                sx={{ textAlign: ["center", "left"] }}
              >
                Do you want to double (or triple) your income while leading
                authentically and making a lasting impact? Reach out and let’s
                connect.
              </Paragraph>
            </Flex>
            {!formState.isSubmitted ? (
              <Grid
                id="contactForm"
                c={1}
                p={16}
                px={[3, 64]}
                as="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid w={"260px"} a={0}>
                  <Grid c={1}>
                    <Input
                      name="name"
                      placeholder="Your Name"
                      ref={register({ required: true, maxLength: 20 })}
                    />
                    {errors.name?.type === "required" && (
                      <span>This field is required</span>
                    )}

                    {errors.name?.type === "maxLength" && (
                      <span>The name is too long</span>
                    )}
                  </Grid>
                  <Grid c={1}>
                    <Input
                      name="email"
                      placeholder="Your Email"
                      ref={register({
                        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                      })}
                    />
                    {errors.email?.type === "required" && (
                      <span>"Your email is required"</span>
                    )}
                    {errors.email?.type === "pattern" && (
                      <span>"Enter a valid email address"</span>
                    )}
                  </Grid>
                </Grid>
                <Grid w={"260px"} a={0}>
                  <Grid c={1}>
                    <Input
                      name="phone"
                      placeholder="Your Phone Number"
                      ref={register({ required: true, maxLength: 20 })}
                    />
                    {errors.name?.type === "required" && (
                      <span>This field is required</span>
                    )}

                    {errors.name?.type === "maxLength" && (
                      <span>Enter a phone number</span>
                    )}
                  </Grid>
                  <Grid c={1}>
                    <Select name="budget" ref={register({ required: true })}>
                      <option selected value="" hidden>
                        Estimated Budget
                      </option>
                      <option value="1k-3k">$1000 - $3000</option>
                      <option value="3k-5k">$3000 - $5000</option>
                      <option value="5k-7.5k">$5000 - $7500</option>
                      <option value="7k.5k+">$7500+</option>
                    </Select>
                  </Grid>
                </Grid>
                <Textarea
                  name="message"
                  placeholder="Please tell us anything else you'd like to know..."
                  ref={register({ required: true })}
                  rows={10}
                ></Textarea>
                <Flex j={2} a={1} py={4}>
                  <Button
                    disabled={
                      !formState.dirty ||
                      (formState.dirty && !formState.isValid)
                    }
                    type="submit"
                    color="white"
                  >
                    <span>
                      Send Message
                      <Image ml={"12px"} src={sendarrow} />
                    </span>
                  </Button>
                </Flex>
              </Grid>
            ) : (
              <Heading
                as="h2"
                color="black"
                py={120}
                sx={{ textAlign: "center" }}
              >
                Form submitted successfully
              </Heading>
            )}
          </Flex>
        </Grid>
      </Container>
      {/*</Sheath>*/}
    </Section>
  );
}
