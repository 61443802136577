import React from "react";
import { Box, Heading, Image } from "theme-ui";
import {
  Flex,
  Grid,
  Divider,
  Container,
  Sheath,
  Anchor,
  Paragraph,
  Section
} from "dunkmore";
import { testimonials } from "classes/testimonial";

import playbutton from "images/artifacts/playbutton.png";

export const sizes = {
  testimonial: "420px",
  avatar: "302px",
  title: "240px"
};

export default function Testimonials() {
  return (
    <>
      {/* heading and container */}
      <Section id="reviews">
        <Container px={[16, 32]} py={60}>
          <Flex j={1} a={1} d={1} l={1} z={3}>
            <Heading
              as="h1"
              color="black"
              variant="heading"
              sx={{ textAlign: "center" }}
            >
              <Box sx={{ fontSize: "24px" }}>What his Clients</Box>
              <Box>ARE SAYING</Box>
            </Heading>
            <Divider
              variant="black"
              sx={{
                position: "relative",
                width: "20%",
                margin: ["18px auto 0", "18px 0 0"]
              }}
            >
              <Box
                sx={{
                  backgroundColor: "primary",
                  height: "8px",
                  width: "60%",
                  position: "absolute",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  zIndex: "-1",
                  margin: "0 auto"
                }}
              ></Box>
            </Divider>
          </Flex>
          <Sheath my={6} l={1} z={3} py={32} d={1} a={1}>
            <Grid c={[1, 2]} mt={24} f={1}>
              {testimonials.map((testimonial, index) => (
                <Flex c={12} key={index} py={4}>
                  <Sheath variant={sizes.testimonial} d={1} a={1}>
                    <Sheath variant={sizes.avatar} l={1}>
                      <Anchor
                        data-lity
                        href={testimonial.video ? testimonial.video : ""}
                        className={
                          testimonial.video ? "has-floating-playbutton" : ""
                        }
                        t
                      >
                        <Image src={testimonial.img} alt={testimonial.name} />
                        <Image className="playbutton" src={playbutton} />
                      </Anchor>
                    </Sheath>
                    <Flex j={1} a={1} d={1} py={4} px={2}>
                      <Heading as="h2" variant="subheading" pt={3}>
                        {testimonial.name}
                      </Heading>
                      <Sheath variant={sizes.title} j={1}></Sheath>
                      <Paragraph variant="testimonial" pt={5}>
                        {testimonial.quote}
                      </Paragraph>
                    </Flex>
                  </Sheath>
                </Flex>
              ))}
            </Grid>
          </Sheath>
        </Container>
      </Section>
    </>
  );
}
