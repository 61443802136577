import React from "react";
import { Image, Heading, Box } from "theme-ui";
import {
  Sheath,
  Container,
  Grid,
  Flex,
  Button,
  BorderButton,
  scrollToEl,
  Paragraph
} from "dunkmore";

import playhero from "images/playhero.png";
import herobg from "images/herobg.jpg";
import herobgxs from "images/herobgxs.png";
import herobgmd from "images/herobgmd.png";
import buttonarrow from "images/artifacts/buttonarrow.png";
import playbuttonsm from "images/artifacts/playbuttonsm.png";

export default function Hero() {
  return (
    <Container variant="site">
      <Flex
        j={1}
        a={0}
        fw={1}
        h={[748, 864]}
        bgi={[
          `url("${herobg}")`,
          `url("${herobg}")`,
          `url("${herobg}")`,
          `url("${herobg}")`
        ]}
        bgp={["-300px", "0 0"]}
        bgr={0}
        bgs={"cover"}
      >
        <Container pt={["35%", "20%"]}>
          <Grid c={1} m={24}>
            <Flex f={1} d={1} l={1} z={3}>
              <Heading
                variant="hero"
                as="h1"
                sx={{
                  textTransform: "capitalize",
                  fontSize: ["32px", "60px"],
                  color: "white"
                }}
              >
                I help high achieving businessmen lead their families with
                integrity while leaving a lasting legacy.
              </Heading>
              <Paragraph
                variant="heroSubheading"
                sx={{
                  marginTop: "24px",
                  maxWidth: "800px"
                }}
              >
                Focused on helping high achieving men lead their families with
                integrity and creating a thriving environment for personal and
                professional growth.
              </Paragraph>

              <Flex
                d={0}
                a={1}
                mt={["12px", "24px"]}
                mb={["42px", "24px"]}
                sx={{ flexWrap: "wrap" }}
                mw={["300px", "100%"]}
              >
                <Button
                  onClick={e => scrollToEl("contact")}
                  variant="white"
                  px={24}
                  mr={24}
                  mt={24}
                  sx={{
                    transform: ["scale(1)"],
                    transformOrigin: "left",
                    background: "white",
                    color: "text",
                    minWidth: "260px",
                    minHeight: "60px",
                    fontSize: ["14px", "18px"]
                  }}
                >
                  <Box
                    sx={{
                      width: "80px",
                      height: "100%",
                      background: [theme => theme.colors.primary],
                      position: "absolute",
                      right: "0",
                      top: "0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Image src={buttonarrow}></Image>
                  </Box>
                  <Box as="span" sx={{ marginRight: "80px" }}>
                    Book Now
                  </Box>
                </Button>
                <BorderButton
                  data-lity
                  href="https://vimeo.com/442948141/22e76b7aa7"
                  px={24}
                  mr={24}
                  mt={24}
                  sx={{
                    transform: ["scale(1)"],
                    transformOrigin: "left",
                    color: "white",
                    minWidth: "260px",
                    minHeight: "60px",
                    fontSize: ["14px", "18px"]
                  }}
                >
                  <Flex j={1} a={1}>
                    <Image src={playbuttonsm} mr={"3"} />
                    Watch this Video
                  </Flex>
                </BorderButton>
              </Flex>
            </Flex>
          </Grid>
        </Container>
      </Flex>
    </Container>
  );
}
