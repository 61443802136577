import fb from "images/facebook.png";
import ig from "images/instagram.png";
import linkedin from "images/linkedin.png";
import youtube from "images/youtube.png";

const Link = (id, link, span, img, variant) => ({
  id,
  link,
  span,
  img,
  variant
});
export const links = [
  Link(1, "about", "About", null, "navlink"),
  Link(2, "features", "Features", null, `"navlink"`),
  Link(3, "reviews", "Reviews", null, `"navlink"`),
  Link(4, "contact", "Contact", null, `"navlink"`),
  Link(
    5,
    "https://www.facebook.com/jeremy.harvey.330",
    null,
    fb,

    null
  ),
  Link(6, "https://www.instagram.com/jayrharv/?hl=en", null, ig, null),
  Link(
    7,
    "https://www.linkedin.com/in/jay-harvey-a15b0a1a9/",
    null,
    linkedin,
    null
  ),
  Link(8, "https://www.youtube.com/", null, youtube, null),
  Link(9, "contact", "Invite Matt", null, null)
];

export const navLeft = links.slice(0, 4);
export const social = links.slice(4, 7);
export const navRight = links.slice(6);
