import review1 from "images/reviews/tasha1.jpg";
import review2 from "images/reviews/john1.jpg";
import review3 from "images/reviews/jasmine1.jpg";
import review4 from "images/reviews/jason1.jpg";
import review5 from "images/reviews/brandon1.jpg";
import review6 from "images/reviews/lee.jpg";

const Testimonial = (img, name, title, quote, video) => ({
  img,
  name,
  title,
  quote,
  video
});

export const testimonials = [
  Testimonial(
    review1,
    "Tasha",
    `Title`,
    `"Working with Jay has been one of the greatest investments I’ve ever made. His coaching and mentorship has taken me to place of full confidence in myself and my potential."`,
    "https://vimeo.com/442940048/d3d2112e40"
  ),
  Testimonial(
    review2,
    "John",
    `Title`,
    `"I’m honored to call Jay a friends and a business partner. He’s been able to challenge me and take my life to the next level. Bringing Jay to your event or enterprise would do the same for you."`,
    "https://vimeo.com/442940169/a21ff3b6d6"
  ),
  Testimonial(
    review3,
    "Jasmine",
    `Title`,
    `"What an incredible experience of working with Jay for a year. His ability to give courageously and loving will not only rock your audience on stage but provide value for years to come."`,
    "https://vimeo.com/442940174/9f35417010"
  ),
  Testimonial(
    review4,
    "Jason",
    `Title`,
    `"As a business owner and entrepreneur for years, it’s been one of the most pivotal experiences to work with Jay. He’s a man of integrity and confidence. Hearing him speak and lead has not only impacted my business but also opened my heart to new possibilities."`,
    "https://vimeo.com/442940171/641f64f553"
  ),
  Testimonial(
    review5,
    "Brandon",
    `Title`,
    `"Working with Jay has been one of the most rewarding experiences for me. Listening to Jay speak has motived and impacted not only in business but in life. Hiring Jay was one of the greatest investments I’ve made."`,
    "https://vimeo.com/442940173/2c86860b5e"
  ),
  Testimonial(
    review6,
    "Lee",
    `Title`,
    `"The level of integrity and honesty that Jay has is above and beyond. He brings a high level of excitement to any stage or any one that he has the chance to speak. Make the investment to bring Jay to your stage or organization."`,
    "https://vimeo.com/442940172/1c3aadc446"
  )
];
