import React from "react";
import { Box, Heading, Image } from "theme-ui";
import {
  Flex,
  Grid,
  Section,
  Divider,
  Container,
  Sheath,
  Anchor,
  Paragraph,
  Button,
  scrollToEl
} from "dunkmore";

import cta from "images/cta.jpg";

export const sizes = {
  testimonial: "420px",
  avatar: "302px",
  title: "240px"
};

export default function Cta() {
  return (
    <>
      <Section
        id="cta"
        sx={{
          backgroundImage: `url("${cta}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: ["502px", "502px"]
        }}
      >
        <Container px={[16, 32]}>
          <Flex d={0}>
            <Flex c={[12, 7]} j={1} a={[1, 0]} d={1} l={1} z={3} py={80}>
              <Heading
                as="h1"
                variant="heading"
                sx={{
                  textAlign: ["center", "left"],
                  color: "#fff",
                  maxWidth: ["300px", "100%"],
                  fontSize: ["24px", "48px"]
                }}
              >
                If you’re a successful business owner...
              </Heading>

              <Divider
                variant="white"
                sx={{
                  position: "relative",
                  width: "80%",
                  margin: ["18px auto 0", "18px 0 0"]
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "primary",
                    height: "8px",
                    width: "60%",
                    position: "absolute",
                    left: "0",
                    right: ["0", "auto"],
                    bottom: "0",
                    zIndex: "-1",
                    margin: ["0 auto", "0"]
                  }}
                ></Box>
              </Divider>
              <Container py={4} mw={"800px"}>
                <Paragraph color="white" sx={{ textAlign: ["center", "left"] }}>
                  ...and feel like you’ve hit a wall or on an endless cycle of
                  “stuck”...reach out to schedule a complimentary clarity call
                  and we’ll get you on the right path.
                </Paragraph>
                <Flex py={5} j={[1, 0]} a={1}>
                  <Button onClick={e => scrollToEl("contact")} variant="white">
                    Invite Jay
                  </Button>
                </Flex>
              </Container>
            </Flex>
          </Flex>
        </Container>
      </Section>
    </>
  );
}
